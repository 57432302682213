import api from 'services/api'
import {
  documentByTypeData,
  DocumentResponseTypes,
  DocumentTypes,
  FormattedDocumentsTypes,
  PublicDocumentResponseTypes,
  RecipesResponseTypes,
  ResponseOrderDocumentTypes
} from '../types/documentTypes'

interface responseDocument {
  documents: DocumentResponseTypes[]
  recipies: RecipesResponseTypes[]
  recipes?: RecipesResponseTypes[]
  success?: boolean
  order?: ResponseOrderDocumentTypes
}

const documentTypeArray: DocumentTypes[] = [
  'RESIDENCIAL',
  'RECEITA',
  'IDENTIDADE',
  'ANVISA',
  'IDENTIDADE_RESPONSAVEL',
  'PROCURACAO',
  'VINCULO'
]

type ErrorMessage = {
  message: string
}

type ResponseWithErrorMessage<T> = T | ErrorMessage

export const handleSaveDocument = async ({
  type,
  order_id,
  uuid,
  file
}: {
  type: DocumentTypes
  order_id: string | number
  file: File
  uuid?: string | number
}): Promise<ResponseWithErrorMessage<FormattedDocumentsTypes>> => {
  const DOCUMENT_ROUTES = uuid ? 'order_public/document' : 'order/document'
  const url =
    type === 'RECEITA'
      ? `/order/send_recipe/${order_id}`
      : `${DOCUMENT_ROUTES}/${uuid || order_id}?type=${type}&emission=18%2F11%2F2022`
   
  try {

    const formData = new FormData()
    formData.append('file', file)
    const { data } = await api.post(url, formData)
    const responseData = type === 'RECEITA' ? data?.recipeSalved : data?.document
    const documentId = type === 'RECEITA' ? '' : data?.id
    const linkUrl = type === 'RECEITA' ? responseData.link_pdf : responseData?.url

    if (responseData) {
      if(type === 'IDENTIDADE'){
        const fileData : any = {
          type: 'identityDocument',
          documentId : responseData?.id,
          extension: linkUrl.split('.').pop(),
          fileUrl: linkUrl
        }
  
        await validateIdentityDocument({ order_id: order_id}, {documentData: fileData });

        const validator = await api.get(`anvisaAuth/order/${order_id}`)

        if(validator?.data?.success && validator?.data?.message === 'waitingDocs'){
          const response = await api.post('anvisaAuth/launchAnvisa',{
            order_id: typeof order_id === 'string' ? parseInt(order_id) : order_id
          }) 
        } else if(validator?.data?.success && validator?.data?.message === 'inexist'){
          const response = await api.post('anvisaAuth/clickAutomation',{
            order_id: typeof order_id === 'string' ? parseInt(order_id) : order_id
          }) 
        }
      }
 
      return {
        ...responseData,
        status: 'new_file',
        type,
        url: linkUrl || responseData?.url
      }
    }
    throw new Error()
  } catch (e) {
    return { message: 'Não foi possivel enviar o arquivo, tente novamente.' }
  }
}

const validateBlankNull = (value: any) => !value;

const validateIdentityDocument = async ({
  order_id,
}: {
  order_id: string | number,
}, {
  documentData,
}: {
  documentData: any;
}) => {
  try{
    const { data } = await api.get(`/order/${order_id}`)
    const order = data?.order
    const person = data?.order?.person
    const originCheck = ['acolhimento-cannect','B2C','COD B','Recipe']

    if(originCheck.some((origin) => origin === order.originCheckout)){
      if((validateBlankNull(person?.birthday) || validateBlankNull(person?.rg))){

        const response = await api.post('/anvisaAuth/validateDoc', documentData)
  
        if(response?.data){
          const responseValidate = response?.data?.data
          if(responseValidate?.similarPatient?.id === person?.id){
            try {
              const newPerson = { ...data?.order?.person, birthday: responseValidate?.birthday, rg: responseValidate?.rg }
              const responseData = await api.post('/order_b2c/create_person', newPerson)
              if(responseData?.data?.success){
                return 'success'
              }
            } catch (e: any) {
              console.error(e)
              const messageError = e?.response?.data?.message[0]?.errors[0]
              return 'error'
            }
          }
        }
      }
    }
  }catch(e){

  }
}

export const getOrderCodB = async ({
  order_id
}: {
  order_id: number
}): Promise<ResponseWithErrorMessage<documentByTypeData>> => {
  try {
    const { data } = await api.get<responseDocument>(`/order/${order_id}/documents`)

    if (data.success) {
      const documents = data?.order?.documents?.flat() || data?.documents?.flat()
      const recipes = data?.order?.recipies || data?.order?.recipes || data?.recipies || data?.recipes
      const formattedRecipes = (recipes || []).map(recipe => ({
        id: recipe.id,
        type: 'RECEITA',
        url: recipe.url,
        name: recipe.filename,
        status: recipe.status,
        extension: recipe.extension
      }))

      const formattedDocument = (documents || []).map(document => ({
        id: document.id,
        type: document.type,
        url: document.url,
        name: document.originalName,
        status: document.status,
        extension: document.extension
      }))

      const returnData = [...formattedRecipes, ...formattedDocument]
      const transformedObject = documentTypeArray.reduce((acc, key) => {
        acc[key] = returnData.filter(item => item.type === key && item.status !== 'denied')

        return acc
      }, {} as Record<DocumentTypes, any>)

      return transformedObject
    }

    throw new Error()
  } catch (e) {
    return { message: 'Não foi possivel buscar lista de docuemntos. ' }
  }
}

export const getOrderB2c = async ({
  order_uuid
}: {
  order_uuid: string
}): Promise<ResponseWithErrorMessage<PublicDocumentResponseTypes>> => {
  try {
    const { data } = await api.get<responseDocument>(`/order_b2c_public/${order_uuid}`)

    if (data.success) {
      const documents = data?.order?.documents || data?.documents
      const recipes = data?.order?.recipies || data?.order?.recipes || data?.recipies || data?.recipes
      const formattedRecipes = (recipes || []).map(recipe => ({
        id: recipe.id,
        type: 'RECEITA',
        url: recipe.url || recipe.link_pdf,
        name: recipe.filename,
        status: recipe.status,
        extension: recipe.extension
      }))

      const formattedDocument = (documents || []).map(document => ({
        id: document.id,
        type: document.type,
        url: document.url,
        name: document.originalName,
        status: document.status,
        extension: document.extension
      }))

      const returnData = [...formattedRecipes, ...formattedDocument]
      const transformedObject = documentTypeArray.reduce((acc, key) => {
        acc[key] = returnData.filter(item => item.type === key && item.status !== 'denied')

        return acc
      }, {} as Record<DocumentTypes, any>)

      return {
        ...transformedObject,
        order_id: data?.order?.id
      }
    }

    throw new Error()
  } catch (e) {
    return { message: 'Não foi possivel buscar lista de docuemntos. ' }
  }
}